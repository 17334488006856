/* Fonts Start */
@font-face {
    font-family: 'Roboto-bold';
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-black';
    src: url('../fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-light';
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-medium';
    src: url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-regular';
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Fonts End */
body,
html {
    width: 100%;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
}

/* Variable Style Start */
:root {
    --dark-blue: #034ea2;
    --light-blue: #f6faff;
    --dim-blue: #a5c0de;
    --sky-blue: #489fff;
    --white: #ffffff;
    --black: #000000;
    --pink: #ec1968;
    --green: #68d100;
    --gold: #e8a835;
    --grey: #bfbec2;
}

/* Variable Style End */

/* Common Css Start */
.text-blue {
    color: var(--dark-blue) !important;
}

.text-sky-blue {
    color: var(--sky-blue);
}

.text-dim-blue {
    color: var(--dim-blue);
}

.text-gold {
    color: var(--gold);
}

.text-grey {
    color: var(--gray);
}

.text-pink {
    color: var(--pink);
}

.text-black {
    color: var(--black);
}

.bg-blue {
    background: var(--dark-blue) !important;
}

.blue-shadow {
    box-shadow: 0px 3px 6px #c0deffab;
    background-color: var(--white);
}

.overflow-hidden {
    overflow: hidden;
}

.border-blue {
    &:focus {
        border-color: var(--dark-blue) !important;
        outline: none !important;
    }
}

button {
    &:focus {
        outline: none;
    }
}

.text-underline {
    text-decoration: underline;
}

.f-12 {
    font-size: 12px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;
}

.f-28 {
    font-size: 28px !important;
}

.f-30 {
    font-size: 30px;
}

.f-rob-reg {
    font-family: 'Roboto-regular';
}

.f-rob-med {
    font-family: 'Roboto-medium';
}

.f-rob-bol {
    font-family: 'Roboto-bold' !important;
}

.f-rob-bla {
    font-family: 'Roboto-black';
}

.head-fix {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
}

.pb-80 {
    padding-bottom: 82px;
}

.pt-90 {
    padding-top: 92px;
}

.pt-190 {
    padding-top: 188px;
}

.rounded-btn {
    border: 0;
    border-radius: 25px;
    outline: none;
    box-shadow: none;
    padding: 12px 40px;
    height: 50px;
    width: 150px;

    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
    }
}
.personal-detailextra-main{
    .personal-form{
        margin:0 0 100px;
    }
}
.proceed-btn {
    background: transparent linear-gradient(102deg, #034ea2 0%, #0073f5 100%) 0% 0% no-repeat
        padding-box;
    border: 0;
    outline: none;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
}

.get-started-btn {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: #ec1968;
    border: 0;
    outline: none;
}

.common-form {
    background-color: var(--white);

    label {
        font-size: 18px;
        font-family: 'Roboto-regular';
    }

    .input-style {
        height: 40px;
        width: 100%;
    }

    .date-icon {
        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.btn {
    box-shadow: none !important;
}

.br- {
    border-radius: 3px;
}

.br-10 {
    border-radius: 10px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--green);
    background-color: var(--green);
}

/* Common Css End */

/* Custom Checkbox Start */
/* The check-main */
.check-main {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Roboto-regular';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.check-main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--white);
    border: 1px solid #c9c9c9;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.check-main:hover input ~ .checkmark {
    border: 1px solid #c9c9c9;
}

/* When the checkbox is checked, add a blue background */
.check-main input:checked ~ .checkmark {
    background-color: var(--dark-blue);
    border: 1px solid var(--dark-blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-main input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-main .checkmark:after {
    left: 8px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Custom Checkbox End */

/* Personal Details Page Start */
.removebtn{
    background: no-repeat;
    border: none;
    color: red;
    font-weight: bold;
    text-align: right;
    top: 17px;
    font-size: 14px;
    right: 40px;
    position: absolute;
}
.personal-details-main {
    background-color: var(--light-blue);
    padding-bottom: 82px;

    .personal-form {
        background-color: var(--white);

        label {
            font-size: 19px;
            font-family: 'Roboto-regular';
            background-color: var(--white);
            padding-right: 10px !important;
        }

        .input-style {
            // height: 40px;
            width: 100%;
        }

        .date-icon {
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }
    .custom-chip {
        span {
            border-radius: 0 !important;
            margin: 0 !important;
        }
    }
}

/* Personal Details Page End */

/* Payment Page Start */
.payment-page {
    @media screen and (min-width: 375px) {
        .payment-check {
            position: fixed;
            bottom: 100px;
        }
    }
}
.paymenthistorytitle{
    box-shadow: 0px 3px 6px #c0deffab;
    background-color:#ffffff;
    padding:1rem 0!important;
    a{
        i{
            font-size: 22px;
            color:#034ea2;
        }
    }
    h2{
        font-size: 22px;
        color:#034ea2;
        margin:0;
        font-weight:bold;
    }
    span{
        font-size: 14px ;
        color:#a5c0de;
    }
}
.paymenthistorycard{
    background: #ffffff;
    box-shadow: 0px 3px 6px #C0DEFF78;
    border-radius: 10px;
    padding:15px;
    margin:20px 0;
    table{
        width:100%;
        tr{  
            line-height: 1.8;
            th{ text-align: left;
                &:last-child{
                    text-align: right;
                    color:#034EA2;
                    font-size:26px;
                }
                b{
                    color:#202733;
                    font-size:18px;
                }
                p{
                    span{
                        color:#034EA2;
                    }
                }
            }
            td{     padding-top:10px;
                
                text-align:right;
                &:first-child{
                    margin-top:20px;
                    opacity: 0.5;
                    text-align:left;
                }
            }
            &:nth-child(2){
                border-top:1px solid #C0DEFF78;
            }
        }
    }
    .ft-650{
        font-weight: 650 !important;
    }
    a{
        color: #034EA2;
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        margin: 0 auto;
        display: inline-block;
        border-top: 1px solid #C0DEFF78;
        margin-top: 20px;
        padding-top: 15px;
        i{
            margin:10px 0;
            vertical-align:middle;
        }
    }
}
/* Payment Page End */

/* Family Details Page Start */
.family-details {
    .family-form {
        background-color: var(--white);

        label {
            font-size: 18px;
            font-family: 'Roboto-regular';
            padding-right: 10px !important;
        }

        .input-style {
            height: 40px;
            width: 100%;
        }

        .date-icon {
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }
}

/* Family Details Page End */

/* Support Page Start */
.support-main {
    .or-main {
        p {
            &:before {
                content: '';
                position: absolute;
                left: 15%;
                top: 50%;
                height: 2px;
                width: 30%;
                background: #80868b17;
            }

            &:after {
                content: '';
                position: absolute;
                right: 15%;
                top: 50%;
                height: 2px;
                width: 30%;
                background: #80868b17;
            }
        }
    }
}

/* Support Page End */

/* Payment Recieved Start */
.payment-recieved {
}

/* Payment Recieved End */

/* Plan Details Start */
.plan-details {
    .plan-card {
        height: 220px;
        padding: 30px;
        border-radius: 15px;
        background-image: url('../../assets/images/rings.png');
        background-position: top right;
        background-repeat: no-repeat;

        .plan-price {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 90%;
                height: 2px;
                right: 0;
                bottom: 0;
                background-color: var(--white);
            }
        }
    }

    .plan-details-collapse {
    }

    .card-slider {
        height: 140px;
        width: 325px;
        border-radius: 10px;
        padding: 15px;
        background-image: url('../../assets/images/compare-card.png');
        background-repeat: no-repeat;
    }

    .testimonial-card {
        min-height: 140px;
        width: 325px;
        padding: 20px;
    }

    .slick-dots li button:before {
        font-size: 10px;
        color: #034ea2;
    }

    .slick-dots li.slick-active button:before {
        color: #034ea2;
    }
}

/* Plan Details End */

/* Thanks Page Start */
.thanks-page {
    .thanks-content {
        height: calc(100vh - 190px);
        display: flex;
        align-items: center;
    }
}

/* Thanks Page End */

/* Medical Detail Page Start */
.medical-detail-page {
    .medical-detail-content {
        height: calc(100vh - 136px);
        display: flex;
        align-items: center;
    }
}

/* Medical Detail End */
// .selectfixed{
//     .MuiPaper-root{
//         top:16px !important;
//     }
// }
// .mulselectbutton{
//     position: fixed;
//     z-index: 99999;
//     bottom: 60px;
//     left:0;
//     right:0;
//     width: 92%;
//     margin:0 auto;
//     border: none;
//     padding: 10px 0;
//     color:#FFF;
//     background: transparent linear-gradient(102deg, #034ea2 0%, #0073f5 100%) 0% 0% ;
// }
.plandetails{
    span{
        padding:10px 6px 0;
    }
    span, div{
        color: #6c757d;
        display:block;
        font-size:12px;
        font-family: 'Roboto-regular';
    }
    a{
        color: #034EA2;
        font-size:14px;
    }
}
.medicalselect{
    border: 1px solid #ccc !important;
    border-radius: 5px;
}
.medicalselect .MuiChip-root{
    background-color: transparent !important;
    border: none !important;
}
.medicalselect .MuiInput-underline:before{
    border-bottom:none;
}
.medicalselect .MuiSelect-select.MuiSelect-select{
    padding-top:10px;
    padding-bottom:10px;
}
.MuiChip-root {
    border: 1px solid #d4d6d6 !important;
    border-radius: 0 !important;
    margin-right: 2px !important;
    padding: 3px !important;
    height: 30px !important;
    display: inline-block !important;
    align-items: center !important;
}

.add-member-btn {
    width: 250px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid var(--dark-blue);
    color: var(--dark-blue);
    font-family: 'Roboto-regular';
    font-size: 18px;
    background-color: #f4faff !important;
}

.get-started-button {
    background-color: #ee4c68;
    color: #ffffff;
    padding: 10px 40px;
    border-radius: 3px;
    &:focus {
        color: #ffffff;
    }
}

/*Loader CSS*/

.loader {
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
    display: flex;

    .loading > div {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 50%;
        margin-left: -30px;
        top: 50%;
        margin-top: -30px;
    }

    .loading > div > div {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background: #489fff;
        top: 10px;
        left: 10px;
        transform-origin: 20px 20px;
        border-radius: 8px;
        animation: spin-a 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
    }

    .loading > div > .c2 {
        top: 10px;
        left: auto;
        right: 10px;
        transform-origin: -4px 20px;
        animation: spin-b 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
    }
    .loading > div > .c3 {
        top: auto;
        left: auto;
        right: 10px;
        bottom: 10px;
        transform-origin: -4px -4px;
        animation: spin-c 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
    }
    .loading > div > .c4 {
        top: auto;
        bottom: 10px;
        transform-origin: 20px -4px;
        animation: spin-d 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
    }

    @keyframes spin-a {
        0% {
            transform: rotate(90deg);
        }
        0% {
            transform: rotate(90deg);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin-b {
        0% {
            transform: rotate(90deg);
        }
        25% {
            transform: rotate(90deg);
        }
        25% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin-c {
        0% {
            transform: rotate(90deg);
        }
        25% {
            transform: rotate(90deg);
        }
        50% {
            transform: rotate(180deg);
        }
        50% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin-d {
        0% {
            transform: rotate(90deg);
        }
        25% {
            transform: rotate(90deg);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(270deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

/* Slick Css Start */
.slick-dots li button:before {
    font-size: 12px !important;
    color: #034ea2 !important;
}

.slick-dots li.slick-active button:before {
    color: #034ea2 !important;
}

/* Slick Css End */

.footer-links-main {
    ul {
        li {
            margin-bottom: 5px;

            a {
                font-size: 12px;
                color: var(--white) !important;
                text-decoration: underline !important;
            }
        }
    }
}

.MuiInputLabel-formControl {
    font-size: 18px !important;
    padding: 0 10px !important;
    background-color: #ffffff !important;
}

.text-black1{
    color: #4A4A4A;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #f4faff !important;
    justify-content: space-between;
}

.MuiSelect-select:focus {
	border-radius: 0px;
	background-color: #f4faff !important;
}


.new-border{
    border-bottom: 1px solid #d2d2d2 !important;
}
